:root {
    --app-bg-color: #f5f5f5;
    --app-bg2-color: #6610f2;
  }
  
  .nav__list {
    padding-left: 2px !important;
  }
  
  .nav ul {
    list-style-type: none;
  }
  
  .nav a,
  .nav label {
    display: block;
    padding: 0.85rem;
    color: #fff;
    background-color: var(--app-bg-color);
  
    -webkit-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    text-decoration: none;
  }
  
  .nav a:focus,
  .nav a:hover,
  .nav label:focus,
  .nav label:hover {
    color: rgba(255, 255, 255, 0.5);
    background: var(--app-bg2-color);
    border-radius: 10px;
  }
  
  .nav label {
    cursor: pointer;
  }
  
  .nav-item > .nav-link {
    text-transform: none;
  }
  /**
   * Styling first level lists items
   */
  
  .group-list a,
  .group-list label {
    padding-left: 0.5rem;
    background: var(--app-bg-color);
  }
  
  .group-list a:focus,
  .group-list a:hover,
  .group-list label:focus,
  .group-list label:hover {
    background: var(--app-bg-color);
  }
  
  /**
     * Styling second level list items
     */
  
  .sub-group-list a,
  .sub-group-list label {
    padding-left: 0.2rem;
    background: var(--app-bg-color);
  }
  
  .sub-group-list a:focus,
  .sub-group-list a:hover,
  .sub-group-list label:focus,
  .sub-group-list label:hover {
    background: var(--app-bg-color);
  }
  
  /**
     * Styling third level list items
     */
  
  .sub-sub-group-list a,
  .sub-sub-group-list label {
    padding-left: 6rem;
    background: var(--app-bg-color);
  }
  
  .sub-sub-group-list a:focus,
  .sub-sub-group-list a:hover,
  .sub-sub-group-list label:focus,
  .sub-sub-group-list label:hover {
    background: var(--app-bg-color);
  }
  
  /**
     * Hide nested lists
     */
  
  .group-list,
  .sub-group-list,
  .sub-sub-group-list {
    height: 100%;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
  }
  
  .nav__list input[type="checkbox"]:checked + label + ul {
    /* reset the height when checkbox is checked */
    max-height: 1000px;
  }
  
  label > span {
    /* float: right;*/
    -webkit-transition: -webkit-transform 0.15s ease;
    transition: transform 0.15s ease;
  }
  
  .nav__list input[type="checkbox"]:checked + label > span {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  