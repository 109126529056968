
.page-section h1::first-letter {
  text-transform: capitalize;
}

.page_section h2 {
  font-size: 44px;
}

.page_section {
  border-top: 1px solid #eee;
}
