
.hours-mobile {
  display: none;
}
@media (max-width: 640px) {
  .hours {
    display: none;
  }

  .hours-mobile {
    display: contents;
    text-align: center;
  }
}

.other-timezone-list {

  border: none !important;
  background-color: #141414 !important;
  color: #6b6767 !important;
}