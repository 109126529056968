main > :first-child {
	padding-top: 0px !important;
}

.account-layout {
display: flex;
flex-wrap: nowrap;
overflow-x: auto;
  }
  
  .account-layout h3 {
    font-weight: normal !important;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  
  .account-layout__left {
    background-color: var(--app-bg-color);
    width: 280px;
    display: flex;
    height: 100vh;
  }
  
  .account-layout__right {
    flex: 0.7;
    flex-grow: 1;
    padding-bottom: 150px;
    padding-left: 20px;
    padding-top: 20px;
  }
  
  .account-layout__title {
    padding-bottom: 10px;
    padding-top: 0px;
    font-size: 32px;
  }
  