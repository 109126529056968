@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;703;800;900&display=swap');

.btn.btn-primary {

    background-color: #4f46e5 !important;
    border-color: #4f46e5 !important;
}

h2 {
    font-size: 2rem !important;
  }

  *{
    font-family: 'Inter', sans-serif;
  }

  .text-glow {
    text-shadow: 0 0 80px rgb(192 219 255 / 75%), 0 0 32px rgb(65 120 255 / 24%);
  }
  
  
.fixedgradient {
  display: block;

  background-image: linear-gradient(to right, rgb(147, 51, 234), rgb(219, 39, 119), rgb(37, 99, 235));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

}
  .animatedgradient {
 
    /*background-image: linear-gradient(to right, rgb(147, 51, 234), rgb(219, 39, 119), rgb(37, 99, 235));*/


    background-clip: text;
    color: transparent;

    
 
        background: linear-gradient(90deg, #c0198d 15%, #2E3192 33%, #00AEEF 50%, #2E3192 67%, #c0198d 85%);
        background-size: 200% 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: gradient-shift 18s infinite linear;
   

  }

  @keyframes gradient-shift {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: 0 0;
    }
  }