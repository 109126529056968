.ul ul   {
   
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  
}
    
.ul > ul>li   {
    list-style-type: disc;
  
}
    

.my-element {
    display: block;
 
    background-image: linear-gradient(to right, rgb(147, 51, 234), rgb(219, 39, 119), rgb(37, 99, 235));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

    
 
   /*     background: linear-gradient(90deg, #c0198d 15%, #2E3192 33%, #00AEEF 50%, #2E3192 67%, #c0198d 85%);
        background-size: 200% 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: gradient-shift 18s infinite linear;
     */

  }

  @keyframes gradient-shift {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  