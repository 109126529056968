/*
#polygone {
  width: 40px;
  height: 40px;
  background: #000;
  clip-path: polygon(40% 0%, 100% 25%, 100% 75%, 40% 100%, 0% 50%);
  opacity: 90;
  animation: poly 1s infinite alternate ease-in-out;
  margin: 1em auto;
}

@keyframes poly {
  from {
    clip-path: polygon(40% 0%, 100% 25%, 100% 75%, 40% 100%, 0% 50%);
  }

  to {
    clip-path: polygon(30% 0%, 90% 25%, 90% 65%, 30% 90%, 0% 40%);
    transform: rotate(360deg);  
    opacity: 0.5;
  }
}
*/

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: grey;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
