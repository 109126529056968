.video-cover {
  position: relative;
 
  height: 85vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.video-cover video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.video-cover .container {
  position: relative;
  z-index: 2;
}

.video-cover .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
 
  opacity: 0.5;
  z-index: 1;
}

/* Media Query for devices withi coarse pointers and no hover functionality */

/* This will use a fallback image instead of a video for devices that commonly do not support the HTML5 video element */

@media (pointer: coarse) and (hover: none) {
  /*
   .video-cover {
    background: url("https://www.mlg-consulting.com/manager_cc/docs/archives/201224151447_pitch-format-large.png")
      black no-repeat center center scroll;
  }
  */

  .video-cover video {
    /* display: none;*/
  }
}
