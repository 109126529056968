.instructions ol > li::first-letter {
    background-color: #1F2937; /* Couleur de fond correspondant à bg-gray-900 dans Tailwind */
 
padding: 50px;
    color: #fff;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.5em;
    margin-right: 0.5em;
    padding: 0.25em 0.5em 0.25em 0.5em;
    border-radius: 0.25em;
    box-shadow: 0.25em 0.25em 0.5em rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
}
  