
.bg-dark #dropdownMenuLink {
  color: #fff !important;
}

.dropdown-toggle {
  text-transform: capitalize !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.dropdown-item:first-letter, .btn:first-letter{
  text-transform: capitalize;

}

.nav-link {
  text-transform: uppercase;
}
 