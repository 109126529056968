.photo-slider {
  height: 300px;
  object-fit: cover;
  /*
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  */
}

.card-title {
  text-transform: capitalize;
}


.splide__list {

	width: auto !important;
}