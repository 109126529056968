 

.splide__slide {
 
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
 
  /*
  filter: brightness(0) invert(1) opacity(0.5);
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
*/}
