:root {
    --logo-whith: 900px;
}
  
  .slider {
 
    position: relative;
    overflow: hidden;
 
  }

  .bg {
    /* transparent au centre 90% red a gauche et droite 10% 
    
    #0a0a0a to rgb 
    */

    
    background-image: linear-gradient(to right,rgba(10,10,10,1) 0%,rgba(10,10,10,0.2) 20%,rgba(0,0,0,0.2) 80%,rgba(10,10,10,1) 100%);
}

  .slider::before,
  .slider::after{
    position:absolute;
    /* background-image:linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,0.2) 100%);
    content:''; */
    height:100%;
    width:25%;
    z-index:2;pointer-events:none;
  }
  .slider::before{
    left:0;
    top:0;
  }
  .slider::after{
    right:0;
    top:0;
    transform:rotateZ(180deg);
  }
  
  /*  IMPORTANT CODE BELOW */
  
  .slide-track {
    width: calc( var(--logo-whith) * 20);
    animation: scroll 20s linear infinite;
    justify-content: space-between;
  }
 
  .slide {
    width:  var(--logo-whith);
  
    place-items: center;
    transition:0.5s;
    background-image: linear-gradient(#fafbff, #858994);
  }
  .slide img {
    width:  var(--logo-whith);
    height: 200px;
    object-fit: contain;
    transition:0.5s;
  }
 
  .slide:hover{
    transform:scale(0.8)
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-150px * 10));
    }
  }
 
  
  @media screen and (max-width: 768px) {
    .slide-track {
      width: calc(250px * 20);
    }
  
    .slide {
      width: 250px;
    }
  
    @keyframes scroll {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(calc(-80px * 10));
      }
    }
  
 
  }
  